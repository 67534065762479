import { Component, EventEmitter, Inject } from "@angular/core";
import { ReplaySubject } from "rxjs";
import { BaseComponent } from "../base.component";
@Component({
  selector: 'select-warehouse',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class SelectWarehouse extends BaseComponent {

}