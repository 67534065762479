import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, forwardRef, Inject, Injector, Input, Output, ViewChild } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { BaseInputComponent } from "@app/admin/components/forms/base-custom-input";
import { NzModalService } from "ng-zorro-antd/modal";
import { Validation } from "../templates/interface";
import { TableEditableService } from "./tableEditable.service";


@Component({
  selector: 'table-editable',
  templateUrl: './index.html',
  styleUrls: ['./index.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TableEditable),
      multi: true
    }
  ]
})
export class TableEditable extends BaseInputComponent {

  @Input() set columns(value: Validation[]) {
    this.tableEditableService.columns.next(value);
  }
  get columns(): Validation[] {
    return this.tableEditableService.columns.getValue();
  }

  @Input() set value(value: any[]) {
    super.value = value;
    this.tableEditableService.setData(value);
  }
  get value(): any[] {
    return super.value;
  }

  dataLength: number[] = [];

  ngOnInit(): void {
    super.ngOnInit();
    this.tableEditableService.dataChanged.subscribe((value) => {
      const data = this.tableEditableService.getData();
      this.selected = data;
      this.onChange(data);
      this.dataLength = Array(data.length).fill('').map((x,i)=>i);
    })
  }

  constructor(@Inject(Injector) protected injector: Injector, private tableEditableService: TableEditableService) {
    super(injector);
  }

  public isSameValue(value1: any, value2: any): boolean {
    return JSON.stringify(value1) === JSON.stringify(value2);
  }

  onDelete(index: number): void {
    this.tableEditableService.removeRow(index);
  }

  trackByIndex(index: number, item: any): number {
    return index;
  }
  trackByColumnKey(index: number, item: Validation): string {
    return item.key;
  }
}