<div class="box-container">
  <div class="box-content">
    <div style="font-size: 24px; font-weight: 500; line-height: 31.2px; padding-top: 20px;">
      Validate result
    </div>
    <div class="top20 bottom20 text-content-bold">Preview your data after mapping.</div>
    <div *ngIf="!canNext" class="invalid-message bottom20">
      <div class="flex">
        <span nz-icon nzType="close-circle" nzTheme="fill" class="right10" style="color: #C01318;"></span>
        <div style="font-size: 14px; font-weight: 500; margin-bottom: 5px;">Invalid data!</div>
      </div>
      <div style="padding-left: 24px;">Please fix data using the below sheet.</div>
    </div>
    <div *ngIf="canNext" class="valid-message bottom20">
      <div class="flex">
        <span nz-icon nzType="check-circle" nzTheme="fill" class="right10" style="color: #52C41A;"></span>
        <div style="font-size: 14px; font-weight: 500; margin-bottom: 5px;">Valid data</div>
      </div>
      <div style="padding-left: 24px;">Preview your shipment data after mapping below. You can start importing these shipments.</div>
    </div>
  </div>
  <table-editable [(ngModel)]="data" [columns]="columns" (ngModelChange)="onChange($event)"></table-editable>
</div>
<div class="flex top20 box-button">
  <button nz-button nzType="default" (click)="back()">
    Back
  </button>
  <button nz-button nzType="primary" (click)="next()" [disabled]="!canNext" [nzLoading]="isLoading">
    Start importing
  </button>
</div>