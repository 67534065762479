import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, Inject, Injector, Input, Output } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { BaseInputComponent } from "@app/admin/components/forms/base-custom-input";
import { InputModule } from "../../../inputs/input.module";
import { BehaviorSubject, Observable, ReplaySubject } from "rxjs";
import { DOCUMENT } from "@angular/common";
import { TableEditableService } from "../../tableEditable.service";
import { Validation } from "../../../templates/interface";
import { BaseComponent } from "@abstract/BaseComponent";
import { distinctUntilChanged, map } from "rxjs/operators";

const IGNORECLASSES = ['cdk-overlay-pane', 'cdk-overlay-backdrop'];
@Component({
  selector: 'editable-cell',
  templateUrl: './index.html',
  styleUrls: ['./index.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditableCell extends BaseComponent {
  @Input() columnKey: string;
  @Input() set rowIndex(value: number) {
    this._rowIndex = value;
    this.getDataFromService(); //fix when add/remove row
  }
  get rowIndex(): number {
    return this._rowIndex;
  }
  private _rowIndex: number;
  canEdit: boolean = true;
  editing = false;
  columnConfig: Validation;
  tableData: any;
  rowData: any;
  cellData: any;
  originalData: any;
  loading: boolean = false;
  displayValue: any;
  error: string;

  constructor(
    private tableEditableService: TableEditableService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subscription.add(this.tableEditableService.columns.subscribe((value) => {
      this.setColumnConfig(value);
      this.getDataFromService();
    }));
    this.subscription.add(this.tableEditableService.dataChanged
      .pipe(
        map(() => {
          // console.log('rowIndex', this.rowIndex, this.tableEditableService.getRowData(this.rowIndex));
          return this.tableEditableService.getRowData(this.rowIndex);
        }),
        distinctUntilChanged((a, b) => {
          const isSame = JSON.stringify(a) === JSON.stringify(b);
          return isSame;
        })
      )
      .subscribe((row) => {
        this.getDataFromService();
      }));
  }

  setColumnConfig(columns: Validation[]) {
    this.columnConfig = columns.find((c: Validation) => c.key === this.columnKey);
    this.canEdit = this.columnConfig?.editInput ? true : false;
  }

  async getDataFromService() {
    if(!this.columnConfig) return; //đợi lấy config
    this.tableData = this.tableEditableService.getData();
    this.rowData = this.tableEditableService.getRowData(this.rowIndex);
    let newCellData = this.rowData[this.columnKey];
    const errors = this.rowData._errors || {};
    const error = errors[this.columnKey] || undefined;
    if (this.isSameValue(newCellData, this.originalData)
      && this.error === error
    ) return;
    this.cellData = newCellData;
    this.originalData = newCellData;
    this.error = error;
    this.loading = true;
    await Promise.all([
      this.renderValue(),
      this.validate()
    ])
    this.loading = false;
    this.cdr.detectChanges();
  }

  setDataToService(cellData: any) {
    this.tableEditableService.setCellData(this.rowIndex, this.columnKey, cellData);
  }


  isSameValue(value1: any, value2: any): boolean {
    return JSON.stringify(value1) === JSON.stringify(value2);
  }

  async renderValue() {
    // console.log('renderValue', this.cellData);
    const value = this.cellData;
    const formatFn = this.columnConfig?.format;
    if (!formatFn) return this.displayValue = value;
    const newValue = await formatFn(value, this.rowData || {});
    this.displayValue = newValue;
  }

  onEdit(): void {
    if (!this.canEdit) return;
    this.editing = true;
  }

  async onStopEdit() {
    this.editing = false;
    this.setDataToService(this.cellData);
  }

  async validate() {
    const validate = this.columnConfig?.validate;
    if (!validate) return;
    if(this.columnConfig?.shouldValidateAll) {
      await this.validateAllColumns();
    } else {
      const error = await validate(this.cellData, this.rowData, this.tableData) || undefined;
      this.tableEditableService.setError(this.rowIndex, this.columnKey, error);
    }
  }

  async validateAllColumns() {
    const validate = this.columnConfig?.validate;
    let promises: any[] = [];
    for(let i=0; i<this.tableData.length; i++) {
      promises.push(validate(this.tableData[i][this.columnKey], this.tableData[i], this.tableData) || undefined);
    }
    const errors = await Promise.all(promises);
    errors.forEach((error, i) => {
      this.tableEditableService.setError(i, this.columnKey, error);
    })
  }
}