import { HeaderConfig, Validation } from "../interface";

export class BaseTemplate {
  static get importHeaders(): HeaderConfig[] {
    throw new Error('Method not implemented.');
  }

  static get validations(): Validation[] {
    throw new Error('Method not implemented.');
  }

  toJSON({ configHeaders, data }: { configHeaders: HeaderConfig[]; data: any[]; }) {

  }

  addShipmentIdsToFile({ data, listOrders, validations }: { data: any[]; listOrders: any[]; validations: Validation[]; }) {

  }

  formatRawData(rawData: any[]) {

  }
}