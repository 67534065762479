import { ApiService } from "@services/api.service";
import { getInjector } from "@services/injector";

export function required(value) {
  if (!value) {
    return 'This field is required';
  }
  return null;
}

export function duplicated(value, data) {
  let count = 0;
  data.forEach((row) => {
    if (row.palletId === value) count++
  });
  if (count>1) return 'This data has already existed in another row';
  return null;
}

export async function verifySiteId(value) {
  //call api to verify site id
  const apiService = getAPIService();
  // const response = await apiService.GET(`site/${value}`);
  return null;
}

const getAPIService = () => {
  return getInjector().get(ApiService);
}