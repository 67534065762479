import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { SharedModule } from "@app/admin/shared/shared.module";
import { SelectBySearchingModule } from "@libs/select-client/module";
import { NzPaginationModule } from "ng-zorro-antd/pagination";
import { MappingData } from ".";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzPaginationModule,
    ScrollingModule,
    SelectBySearchingModule,
    SharedModule,
  ],
  declarations: [
    MappingData
  ],
  exports: [
    MappingData
  ],
  providers: [
  ]
})
export class MappingDataModule {
}
