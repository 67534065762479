<div class="editable-group" (click)="onEdit()"  [ngClass]="{'error': rowData?._errors?.[columnKey], 'canEdit': canEdit}"
[nz-tooltip]="rowData?._errors?.[columnKey]"
>
  <i nz-icon nzType="loading" nzTheme="outline" *ngIf="loading"></i>
  <div class="editable-cell" [hidden]="editing || loading">
    {{ displayValue }}
  </div>

  <div class="editting" *ngIf="editing">
    <dynamic-input [(ngModel)]="cellData" [editInput]="columnConfig.editInput" (onFinish)="onStopEdit()"></dynamic-input>
  </div>
</div>