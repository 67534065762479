import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, forwardRef, Inject, Injector, Input, Output, ViewChild } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { BaseInputComponent } from "@app/admin/components/forms/base-custom-input";
import { ExcelUtil } from "@services/excel-util";
import { NzModalService } from "ng-zorro-antd/modal";
import * as XLSX from "xlsx";

@Component({
  selector: 'xlsx-upload',
  templateUrl: './index.html',
  styleUrls: ['./index.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => XLSXUpload),
      multi: true
    }
  ]
})
export class XLSXUpload extends BaseInputComponent {
  @ViewChild('fileInput') myFileInput;

  @Input() accept: string = '.csv, .xlsx, .xls';
 
  constructor(@Inject(Injector) protected injector: Injector, private modal: NzModalService) {
    super(injector);
  }
  clickUpload() {
    this.myFileInput.nativeElement.click()
  }

  public async changeListener(e) {
    let files: any = e.target.files;
    if (files && files.length > 0) {
      //validate only accept csv, xlsx, xls files
      const fileExtension = files[0].name.split('.').pop();
      if (!['csv', 'xlsx', 'xls'].includes(fileExtension)) {
        this.modal.confirm({
          nzTitle: '<i>File Extension Verification Required</i>',
          nzContent: '<b>Please verify the file extension. The system only accepts files with extensions such as .csv, .xlsx, or .xls.</b>',
          nzOnOk: () => { },
          nzOkText: 'Ok',
          nzCancelText: 'Cancel',
        });
        return
      }
      const file: File | null = files.item(0);
      if (!file) {
        throw 'No File Upload'
      }
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data);
      var firstSheet = workbook.Sheets[workbook.SheetNames[0]];
      var result: [][] = XLSX.utils.sheet_to_json(firstSheet, { header: 1, raw: false });
      if (!result.length) return;
      result = result.filter((item: any) => item?.length)
      this.value = {
        fileName: file.name,
        data: result
      }
    }
    this.myFileInput.nativeElement.value = '';
  }

  public isSameValue(value1: any, value2: any): boolean {
    if (!value1 || !value2) return false;
    return JSON.stringify(value1) === JSON.stringify(value2);
  }
}