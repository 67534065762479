import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, forwardRef, Inject, Injector, Input, Output, ViewChild } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { BaseInputComponent } from "@app/admin/components/forms/base-custom-input";
import { NzModalService } from "ng-zorro-antd/modal";
import * as XLSX from "xlsx";

interface Field {
  label: string; //label hiển thị
  description?: string; //description hiển thị dưới label
  key: string; //key của json output
  required?: boolean;
  multiple?: boolean;
  headers?: string | string[], //output sau khi chọn
  suggestions?: string[] //danh sách các gợi ý có thể chonj
}

@Component({
  selector: 'mapping-data',
  templateUrl: './index.html',
  styleUrls: ['./index.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MappingData),
      multi: true
    }
  ]
})
export class MappingData extends BaseInputComponent<Field[]> {
  @Input() headers: string[] = [];

  private isCheckedSuggestion = false; //chỉ check 1 lần
  constructor(@Inject(Injector) protected injector: Injector, private modal: NzModalService) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.isCheckedSuggestion = false;
  }

  get value(): Field[] {
    return this.selected;
  }

  set value(value: Field[]) {
    console.log('value', value);
    value = this.checkSuggestions(value);
    if (this.isSameValue(this.selected, value)) return;
    this.selected = value;
    this.onChange(this.selected);
    this.onTouch(this.selected);
  }

  checkSuggestions(value: Field[]) {
    if (!value) return value;
    if (this.isCheckedSuggestion) return value;
    this.isCheckedSuggestion = true;
    return value.map(item => {
      if (!item.suggestions?.length) return item;
      const suggestions = item.suggestions.map(s => s.toLowerCase().replace(/[^a-zA-Z0-9]+/g, ''));
      //find header in suggestions
      if (item.multiple) item['headers'] = this.headers.filter(h => suggestions.some(s => h.toLowerCase().replace(/[^a-zA-Z0-9]+/g, '').includes(s)));
      else item['headers'] = this.headers.find(h => suggestions.some(s => h.toLowerCase().replace(/[^a-zA-Z0-9]+/g, '').includes(s)));
      return item;
    })
  }


  public isSameValue(value1: Field[], value2: Field[]): boolean {
    if (!value1 || !value2) return false;
    return JSON.stringify(value1) === JSON.stringify(value2);
  }

  onSelect(field: Field, header: string | string[]) {
    const newValue = JSON.parse(JSON.stringify(this.value));
    const item = newValue.find(it => it.key == field.key);
    if (!item) return;
    item['headers'] = header;
    this.writeValue(newValue)
  }
}