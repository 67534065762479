import moment from "moment"
import { HeaderConfig, iTemplate, Validation } from "../interface";
import { BaseTemplate } from "./baseTemplate";
import { SelectWarehouse } from "../../inputs/SelectWarehouse";
import { TextInput } from "../../inputs/TextInput";
import { NumberInput } from "../../inputs/NumberInput";
import { DatePicker } from "../../inputs/DatePicker";
import { required, verifySiteId, duplicated } from "../validator";
import { Utils } from '@services/utils';
import { APIConcurrent } from "../api.concurrent";
import { getInjector } from "@services/injector";
import { ApiService } from "@services/api.service";
import { BehaviorSubject } from "rxjs";
import { Const } from '@const/Const';
import to from 'await-to-js';
import { WarehouseOperatingHour } from '@wearewarp/types/data-model/types/Warehouse';

const getWarehouseInfo = new APIConcurrent({
  intervalTime: 1000
}).registryHandler(async (data) => {
  const clientId = data.filter(c => c?.clientId)[0]?.clientId;
  const siteId = data.map(c => c?.siteId).filter(c => !!c);
  if(!clientId || !Utils.isArrayNotEmpty(siteId)) return [];
  //unique siteId
  const uniqueSiteId = Array.from(new Set(siteId));
  const query = {
    clientLocationIds: uniqueSiteId,
    clientId: clientId
  }
  const apiService = getInjector().get(ApiService)
  //call api.
  let url = Const.APIV2(`customer-warehouses/search/byClientLocationIds`);
  let [err, resp] = await to(apiService.POST(url, query).toPromise());
  if(err) return [];
  return formatWarehouseData(resp);
});

const formatWarehouseData = (resp) => {
  if(!resp?.data) return [];
  const { customerWarehouses, warehouses } = resp.data;
  let allWarehouses = customerWarehouses || [];
  if(warehouses.length) {
    warehouses.forEach((warehouse) => {
      const index = allWarehouses.findIndex((clientWarehouse) => warehouse.id === clientWarehouse.warehouseId);
      if(index == -1){
        allWarehouses.push(warehouse);
      } else {
        allWarehouses[index] = { ...allWarehouses[index], warehouseData: warehouse };
      }
    });
  }
  return allWarehouses;
}

export class GoPuffByPallet extends BaseTemplate {
  static get importHeaders(): HeaderConfig[] {
    return [
      {
        key: 'locationName',
        label: 'Location Name',
        description: '(e.g. DSM_Des-Moines_97)',
        required: true,
        suggestions: ['locationname', 'location']
      },
      {
        key: 'PONumber',
        label: 'PO Number',
        description: '(e.g. 118527530)',
        required: true,
        multiple: true,
        suggestions: ['ponumber', 'ponum'],
      },
      {
        key: 'palletId',
        label: 'Pallet ID',
        description: '(DC2-WARP-SAN-0000000-0)',
        required: true,
        suggestions: ['palletid'],
      },
      {
        key: 'weight',
        label: 'Weight',
        description: '(e.g. 500)',
        required: true,
        suggestions: ['weight'],
      },
      {
        key: 'deliverDate',
        label: 'Delivery Date',
        description: '(e.g. 1/7/24)',
        required: true,
        suggestions: ['deliverdate', 'Planned Delivery Date', 'DeliverToSite'],
      },
      {
        key: 'pickupTime',
        label: 'Pickup Time',
        description: '(e.g. 08:00-09:00)',
        suggestions: ['pickuptime'],
      },
      {
        key: 'deliveryTime',
        label: 'Delivery Time',
        description: '(e.g. 10:00-16:00)',
        suggestions: ['deliverytime'],
      },
    ]
  }

  static get validations(): Validation[] {
    return [
      {
        key: 'locationName',
        label: 'Location Name',
        width: "200px",
        editInput: TextInput,//SelectWarehouse,
        validate: async (value) => {

        }
      },
      {
        key: 'siteId',
        label: 'Site ID',
        width: "100px",
        subject: new BehaviorSubject<any>({}),
        update: async (value, row) => {
          if (!row?.locationName) return
          const locationName = row['locationName'];
          const splited = locationName.split("_");
          //console.log("update location")
          return splited?.[splited.length - 1] || undefined;
        },
        validate: async (value, row) => {
          const requiredError = required(value);
          if (requiredError) return requiredError;

          const error = await verifySiteId(value);
          if (error) return
        }
      },
      {
        key: 'PONumber',
        label: 'PO Number',
        width: "200px",
        editInput: TextInput,
        validate: async (value) => {
          const requiredError = required(value);
          if (requiredError) return requiredError;

          //validate PO is exist in system or not
        }
      },
      {
        key: 'palletId',
        label: 'Pallet ID',
        width: "200px",
        shouldValidateAll: true,
        editInput: TextInput,
        validate: async (value, row, data) => {
          const requiredError = required(value);
          if (requiredError) return requiredError
          const duplicatedError = duplicated(value, data);
          if (duplicatedError) return duplicatedError
        }
      },
      {
        key: 'weight',
        label: 'Weight',
        width: "150px",
        editInput: NumberInput,
        validate: async (value) => {
          if (value < 0) return 'Weight must be greater than 0';
          const requiredError = required(value);
          if (requiredError) return requiredError
        }
      },
      {
        key: 'deliverDate',
        label: 'Delivery Date',
        width: "150px",
        editInput: DatePicker,
        format: async (value) => {
          if (!value) return
          return moment(value).format("MM/DD/YY")
        },
        validate: async (value) => {
          const requiredError = required(value);
          if (requiredError) return requiredError
          if (moment(value).isBefore(moment(), 'day')) return 'Delivery Date must be in the future'
          //date too far in the future
          if (moment(value).isAfter(moment().add(1, 'month'), 'day')) return 'Delivery Date must be within 1 month'
        }
      },
      {
        key: 'pickupTime',
        label: 'Pickup Time',
        width: "150px",
        editInput: TextInput,
        format: async (value) => {
          return value
        },
        validate: async (value) => {
          //check time format HH:mm - HH:mm
          const timeRegex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9] - ([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
          if (!timeRegex.test(value)) return 'Invalid time format. Please use HH:mm - HH:mm'
        }
      },
      {
        key: 'deliveryTime',
        label: 'Delivery Time',
        width: "150px",
        editInput: TextInput,
        subject: new BehaviorSubject<any>({}),
        subscribeKey: "siteId",
        isSkipUpdate: true,
        setSubscription: (value: BehaviorSubject<any>) => {
          //console.log("call subs")
          value.subscribe(({row, data, columns, subscribeKey}) => {
            if(!data) return;
            getWarehouseInfo.add(row).subscribe(response => {

              for(let i = 0; i < data.length; i++) {
                const { deliverDate, siteId } = data[i];
                const warehouse = response.find(item => item.clientLocId === siteId);

                if (!deliverDate || !siteId || !warehouse) {
                  continue;
                }
                let time = this.getTimeWindow(warehouse, deliverDate);
                if(data[i]['deliveryTime'] !== time) {
                  data[i]['deliveryTime'] = time;
                  let col = columns.find(c => c.subscribeKey == subscribeKey);
                  col.subject.next({row, data: data, columns: columns, subscribeKey: col.key});
                } 
              }
            })
          })
        },
        validate: async (value) => {
          //check time format HH:mm - HH:mm
          const timeRegex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9] - ([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
          if (!timeRegex.test(value)) return 'Invalid time format. Please use HH:mm - HH:mm'
        }
      },
    ]
  }

  static toJSON(data: any[]): any[] {
    let dataMap = new Map();

    for (let i = 0; i < data.length; i++) {
      const shipmentId = `GP-PO-${data[i].PONumber}`;
      if (!dataMap.has(shipmentId)) {
        dataMap.set(shipmentId, this.buildShipment(data[i]));
      }
      const item = this.buildItem(data[i]);
      dataMap.get(shipmentId).items.push(item);
    }
    return Array.from(dataMap.values());
  }

  static buildShipment(data: any): any {
    return {
      shipmentId: `GP-PO-${data.PONumber}`,
      deliveryDate: moment(data.deliverDate).format("MM/DD/YY"),
      refNums: this.formatRefNums(data.PONumber),
      siteId: data.siteId,
      pickupTime: data.pickupTime,
      deliveryTime: data.deliveryTime,
      items: []
    }
  }

  static buildItem(data: any): any {
    return {
      barcodes: [data.palletId],
      weight: data.weight,
      quantity: 1,
    }
  }

  static formatRefNums(refNums) {
    if(Utils.isArray(refNums)) {
      return refNums.join(", ");
    }
    return refNums;
  }

  static addShipmentIdsToFile(data, listOrders, validations, mode) {
    let columns = validations.map(c => c.key);
    let cloneData = [];
    for(let row of data) {
      let rowData = [];
      for(let key of columns) {
        if(key == 'deliverDate') row[key] = moment(row[key]).format("MM/DD/YY");
        rowData.push(row[key]);
      }
      cloneData.push(rowData);
    }
    let refNumsIndex = validations.findIndex(column => column.key=='PONumber');
    cloneData.forEach(row => {
      let refNums = row[refNumsIndex];
      let order = listOrders.find(order => (order.outbound?.refNums || order.refNums || []).includes(refNums));
      if (order) {
        let orderId = order.outbound?.warpId || ( isNaN(order.warpShipmentId) ? order.warpOrderWarpId : order.warpShipmentId );
        row.push(orderId);
      }
    })
    return cloneData;
  }

  static async getWarehousePickupTime(clientId, warehouseId, deliverDate) {
    const query = {
      warehouseIds: [warehouseId],
      clientId: clientId
    }
    const apiService = getInjector().get(ApiService)
    //call api.
    let url = Const.APIV2(`customer-warehouses/search/byWarehouseIds`);
    let [err, resp] = await to(apiService.POST(url, query).toPromise());
    if(err) return null;
    let warehouse = formatWarehouseData(resp)[0];
    let time = this.getTimeWindow(warehouse, deliverDate, true);
    return time;
  }
  
  static getTimeWindow(warehouse, deliverDate, isPickup = false) {
    let from = isPickup ? "08:00" : "10:00";
    let to = isPickup ? "09:00" : "16:00";
    const timeZone = warehouse?.warehouseData?.pickAddr?.metadata?.timeZoneStandard || "America/New_York";
    deliverDate = moment(deliverDate).format('M/D/YY');
    const dateMoment = moment.tz(deliverDate, ['M/D/YY', 'M/D/YYYY', 'YYYY-MM-DD'], timeZone);
    const day = dateMoment.isoWeekday() - 1;
    let workingTime: WarehouseOperatingHour[] = []
    if(isPickup){
      workingTime = warehouse?.pickupTimes || [];
    }
    else{
      workingTime = warehouse?.deliveryTimes || [];
    }
    const openningTime = (<any>workingTime)?.[day]
    let fromTime = openningTime?.fromTime || from;
    let toTime = openningTime?.toTime || to;
    return `${fromTime} - ${toTime}`;
  }

  static formatRawData(rawData: any[]): any[] {
    return rawData.slice(3);
  }
}