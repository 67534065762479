import { Injectable } from "@angular/core";
import { Log } from "@services/log";
import { BehaviorSubject, Subscription } from "rxjs";
import { Validation } from "../templates/interface";

@Injectable()
export class TableEditableService {
  public columns: BehaviorSubject<Validation[]> = new BehaviorSubject<Validation[]>([]);
  public dataChanged: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public errors: BehaviorSubject<string[][]> = new BehaviorSubject([]);
  private data: any[] = [];
  private subscription: Subscription = new Subscription();

  async setData(data) {
    if (!data) return this.data = [];
    await Promise.all(data.map(async (row, index) => {
      await this.setRowData(index, row, false, false);
    }));
    this.dataChanged.next(!this.dataChanged.getValue());
  }

  getData() {
    return [...this.data]
  }

  setSubscription() {
    const columns = this.columns.getValue();
    columns.map((columnConfig) => {
      if (!columnConfig?.subscribeKey) return;
      let col = columns.find(c => c.key == columnConfig.subscribeKey);
      columnConfig.setSubscription(col.subject);
      this.subscription.add(columnConfig.subject.subscribe(({row, data, columns, subscribeKey}) => {
        this.dataChanged.next(!this.dataChanged.getValue());
      }));
    });
  }

  async setRowData(index, row, isSkipUpdate = false, event = true) {
    //update data before update
    if (this.isEmptyRow(row)) return;
    if (!isSkipUpdate) {
      const columns = this.columns.getValue();
      await Promise.all(columns.map(async (columnConfig) => {
        if (!columnConfig?.update) return;
        const cellData = row[columnConfig.key];
        row[columnConfig.key] = await columnConfig.update(cellData, row);
        if (!columnConfig?.subject) return;
        columnConfig.subject.next({row, data: this.data, columns: columns, subscribeKey: columnConfig.key});
      }));
    }

    this.data[index] = {...row};
    if (event) this.dataChanged.next(!this.dataChanged.getValue());
  }
  getRowData(index) {
    return { ...this.data[index] };
  }

  setCellData(rowIndex, columnKey, value) {
    const row = this.getRowData(rowIndex);
    if(this.isSameValue(row[columnKey], value)) return;
    row[columnKey] = value;
    const columns = this.columns.getValue();
    let column = columns.find(column => column.key === columnKey);
    let isSkipUpdate = column.isSkipUpdate === true;
    this.setRowData(rowIndex, row, isSkipUpdate, true);
  }

  setError(rowIndex, columnKey, errorMessage) {
    const row = this.getRowData(rowIndex);
    const errors = row._errors || {};
    if (errors[columnKey] == errorMessage) return;

    errors[columnKey] = errorMessage;
    row._errors = errors;
    this.setRowData(rowIndex, row, true);
  }

  removeRow(index) {
    this.data.splice(index, 1);
    this.dataChanged.next(!this.dataChanged.getValue());
  }

  isEmptyRow(row) {
    return Object.keys(row).filter(key => key !== '_errors' && row[key]).length === 0;
  }

  isSameValue(value1: any, value2: any): boolean {
    return JSON.stringify(value1) === JSON.stringify(value2);
  }
}