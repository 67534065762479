<div style="background-color: white; margin: -16px; padding: 24px 0;">

  <div class="flex border-bottom">
    <span class="form-title-main">Import shipments</span>
    <i class="ic-right-close clickable" nz-icon nzType="close" nzTheme="outline" (click)="onClose()"></i>
  </div>

  <gopuff-upload-general-info *ngIf="step==1"></gopuff-upload-general-info>
  <gopuff-mapping-data *ngIf="step==2"></gopuff-mapping-data>
  <gopuff-upload-data-table *ngIf="step==3"></gopuff-upload-data-table>
</div>