import { BaseComponent } from "@abstract/BaseComponent";
import { Component, ViewChild } from "@angular/core";
import { BaseFormItem } from "@app/admin/base/form-item";
import { Const } from '@const/Const';
import { GopuffGeneralInfoComponent } from './components/form-general-info';
import { GoPuffUploadService } from "./gopuffUpload.service";

@Component({
  selector: 'bulk-upload-new',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class BulkUploadGopuffComponent extends BaseComponent {

  public step = 1;
  constructor(private gopuffUploadService: GoPuffUploadService) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subscription.add(this.gopuffUploadService.step.subscribe(value => this.step = value));
  }

  onClose() {
    this.confirmYesNo(`Are you sure to exit importing shipments page?`, () => {
      this.gopuffUploadService.reset();
      this.router.navigate([Const.routeAdminOrderList]);
    });
  }
}