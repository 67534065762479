import { BaseComponent } from "@abstract/BaseComponent";
import { Component } from "@angular/core";
import { GoPuffUploadService } from "../../gopuffUpload.service";
import { HeaderConfig } from "../../interface";

interface Header {
  label: string; //label hiển thị
  description?: string; //description hiển thị dưới label
  key: string; //key của json output
  required?: boolean;
  header?: string, //output sau khi chọn
  suggestions?: string[] //danh sách các gợi ý có thể chonj
}

@Component({
  selector: 'gopuff-mapping-data',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class GopuffMappingComponent extends BaseComponent {
  constructor(private gopuffUploadService: GoPuffUploadService) {
    super();
  }

  config: HeaderConfig[] = []
  ngOnInit(): void {
    super.ngOnInit();
    this.subscription.add(this.gopuffUploadService.headerConfig.subscribe(headerConfig => {
      this.config = headerConfig
    }))
    console.log('config',this.config)
  }

  onChange(value) {
    this.gopuffUploadService.headerConfig.next(value)
  }

  getHeader() {
    const fileData = this.gopuffUploadService.fileData.getValue()?.data || []
    const header = fileData[0] || []
    return header.filter(it => it);
  }

  back(){
    this.gopuffUploadService.revertFileRawData();
    this.gopuffUploadService.step.next(1);
  }
  next(){
    this.gopuffUploadService.step.next(3);
  }
  canNext(){
    //check if all required fields are filled
    const requiredFields = this.config.filter(it => it.required)
    const filledFields = this.config.filter(it => it.headers?.length)
    return requiredFields.length <= filledFields.length
  }

}