import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { SharedModule } from "@app/admin/shared/shared.module";
import { SelectBySearchingModule } from "@libs/select-client/module";
import { NzPaginationModule } from "ng-zorro-antd/pagination";
import { TableEditable } from ".";
import { InputModule } from "../inputs/input.module";
import { DyanmicInputComponent } from "./components/DynamicInput";
import { EditableCell } from "./components/EditableCell";
import { TableEditableService } from "./tableEditable.service";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzPaginationModule,
    ScrollingModule,
    SelectBySearchingModule,
    SharedModule,
    InputModule,
  ],
  declarations: [
    TableEditable,
    EditableCell,
    DyanmicInputComponent
  ],
  exports: [
    TableEditable
  ],
  providers: [
    TableEditableService
  ]
})
export class TableEditableModule {
}
