import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { SharedModule } from "@app/admin/shared/shared.module";
import { SelectBySearchingModule } from "@libs/select-client/module";
import { ApiService } from "@services/api.service";
import { ModalHelper } from "@wearewarp/ng-antd";
import { NzPaginationModule } from "ng-zorro-antd/pagination";
import { NzTableModule } from "ng-zorro-antd/table";
import { GopuffGeneralInfoComponent } from "./components/form-general-info";
import { BulkUploadGopuffComponent } from ".";
import { GopuffUploadRoutingModule } from "./gopuffUpload.routing";
import { SearchFTLComponent } from "./components/searchFTLInput";
import { NzRadioModule } from "ng-zorro-antd/radio";
import { GoPuffUploadService } from "./gopuffUpload.service";
import { XlsxUploadModule } from "../components/xlsx-upload/xlsxUpload.module";
import { GopuffDataTableComponent } from "./components/form-data-table";
import { MappingDataModule } from "../components/mapping-data/mappingData.module";
import { GopuffMappingComponent } from "./components/form-mapping-data";
import { TableEditableModule } from "../components/table-editable/tableEditable.module";
import { NzImageModule } from 'ng-zorro-antd/image';
import { DlgCreateOrderListSuccess } from "./components/dlg-create-order-list-success";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    GopuffUploadRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NzTableModule,
    NzPaginationModule,
    NzRadioModule,
    ScrollingModule,
    SelectBySearchingModule,
    SharedModule,
    XlsxUploadModule,
    MappingDataModule,
    TableEditableModule,
    NzImageModule,
  ],
  declarations: [
    BulkUploadGopuffComponent,
    GopuffGeneralInfoComponent,
    GopuffMappingComponent,
    GopuffDataTableComponent,
    SearchFTLComponent,
    DlgCreateOrderListSuccess,
  ],
  exports: [
  ],
  providers: [
    // DispatchService,
    ModalHelper,
    ApiService,
    GoPuffUploadService
  ]
})
export class GopuffBulkUploadNewModule {
}
