<div class="box-container">
  <div class="box-content">
    <div nz-row [nzGutter]="16">
      <div nz-col class="gutter-row" [nzSpan]="24">
        <div class="box-title-container">
          <div style="font-size: 22px; font-weight: 500;">Upload file</div>
        </div>
        <div class="box-title">General info</div>
        <div class="form-label-v2">Customer<span class="label-mark-required"></span></div>
        <nz-form-item class="margin0">
          <nz-form-control>
            <select-by-searching [version]="2" [placeholder]="'Search Customer'" [showSubClient]="true"
              [ngModel]="data.clientId" (modelChange)="onChange('clientId',$event)">
            </select-by-searching>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </div>

  <div nz-col class="box-content" [nzSpan]="24">
    <div class="form-label-v2">Shipment Type<span class="label-mark-required"></span></div>
    <nz-form-item style="margin: 0;">
      <nz-form-control>
        <nz-radio-group style="width: 100%" [(ngModel)]="data.mode" (ngModelChange)="onChange('mode',$event)">
          <label nz-radio *ngFor="let item of mode" class="radio-mode"
            [ngClass]="{'radio-mode-selected': data.mode == item.value}" [nzValue]="item.value">
            {{item.label}}
            <div class="option-content" *ngIf="data.mode == item.value">
              <!-- form-custom-by-mode -->
              <searchFTLInput [clientId]="data.clientId" [ngModel]="data.ftlOrder"
                *ngIf="getModeSetting().forms.ftlOrder" (ngModelChange)="onChange('ftlOrder', $event)"></searchFTLInput>

              <ng-container *ngIf="getModeSetting().forms.pickupWarehouseId">
                <div class="form-label-v2">Pickup Warehouse<span class="label-mark-required"></span></div>
                <select-by-searching [version]="2" [placeholder]="'Search Pickup Warehouse'" type="warehouse"
                  [ngModel]="data.pickupWarehouseId" (modelChange)="onChange('pickupWarehouseId',$event)">
                </select-by-searching>
              </ng-container>

              <ng-container *ngIf="getModeSetting().forms.warehouseId">
                <div class="form-label-v2">Crossdock Warehouse<span class="label-mark-required"></span></div>
                <select-by-searching [version]="2" [placeholder]="'Search Crossdock Warehouse'" type="warehouse"
                  [ngModel]="data.warehouseId" *ngIf="getModeSetting().forms.warehouseId"
                  (modelChange)="onChange('warehouseId',$event)">
                </select-by-searching>
              </ng-container>

              <!-- end-form-custom-by-mode -->
            </div>
          </label>
        </nz-radio-group>
      </nz-form-control>
    </nz-form-item>
  </div>

  <div nz-col class="box-content" [nzSpan]="24">
    <div class="form-label-v2">Upload File<span class="label-mark-required"></span></div>
    <nz-form-item>
      <nz-form-control>
        <nz-radio-group style="width: 100%" [ngModel]="data.templateId" (ngModelChange)="onChange('templateId',$event)">
          <label nz-radio *ngFor="let item of templateIds" class="radio-mode" [nzValue]="item.value">
            {{item.label}}
          </label>
        </nz-radio-group>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item style="margin: 0;">
      <nz-form-control>
        <xlsx-upload [ngModel]="data.fileData" (ngModelChange)="onChange('fileData',$event)"></xlsx-upload>
      </nz-form-control>
    </nz-form-item>
  </div>

</div>
<div class="flex top20 box-button">
  <button nz-button nzType="primary" (click)="next()" [disabled]="!canNext()">
    Save & Continue
  </button>
</div>