import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { SharedModule } from "@app/admin/shared/shared.module";
import { SelectBySearchingModule } from "@libs/select-client/module";
import { NzPaginationModule } from "ng-zorro-antd/pagination";
import { SelectWarehouse } from "./SelectWarehouse";
import { TextInput } from "./TextInput";
import { NumberInput } from "./NumberInput";
import { DatePicker } from "./DatePicker";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzPaginationModule,
    ScrollingModule,
    SelectBySearchingModule,
    SharedModule,
  ],
  declarations: [
    SelectWarehouse,
    TextInput,
    NumberInput,
    DatePicker,
  ],
  exports: [
    SelectWarehouse,
    TextInput,
    NumberInput,
    DatePicker,
  ],
  providers: [
  ]
})

export class InputModule {
}
