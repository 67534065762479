import { Injectable } from "@angular/core";
import { Log } from "@services/log";
import { Order } from "@wearewarp/types/data-model";
import { BehaviorSubject, ReplaySubject } from "rxjs";
import { HeaderConfig } from "./interface";
import { XlsxUploadService } from "../components/xlsx-upload/xlsxUpload.service";
import { TemplateService } from "../components/templates/template.service";
import { Utils } from '@services/utils';
interface FileData {
  fileName: string;
  data: any[]
}

@Injectable()
export class GoPuffUploadService {
  public step = new BehaviorSubject<number>(1);
  public mode = new BehaviorSubject<string>('both');
  public clientId = new BehaviorSubject<string>(null);
  public ftlOrder = new BehaviorSubject<Order>(null);
  public fileData = new BehaviorSubject<FileData>(null);
  public warehouseId = new BehaviorSubject<string>(null);
  public pickupWarehouseId = new BehaviorSubject<string>(null);
  public templateId = new BehaviorSubject<string>('GoPuffByPallet');
  public headerConfig = new BehaviorSubject<HeaderConfig[]>([]);
  public errorMessage = new BehaviorSubject<string>(null);

  //editing
  public editingData = new BehaviorSubject<any[]>([]);

  private templateService = new TemplateService();
  private originalFileRawData: any[] = [];

  constructor(private xlsxService: XlsxUploadService) {
    this.templateId.subscribe(templateId => {
      if (!templateId) return;
      //đổi template thì đổi header config
      const headerConfig = this.templateService.setTemplateId(templateId).getHeaderConfigFromTemplate();
      this.headerConfig.next(headerConfig);
    })
  }


  public getDataFromExcel() {
    const headerConfig = this.headerConfig.getValue() || [];
    const data = this.fileData.getValue()?.data || [];
    //data from excel -> convert to json
    const rawData = this.xlsxService.toJSON({
      headerConfig,
      data,
      startRow: 1
    });
    return rawData;
  }

  public getFormattedData() {
    const data = this.editingData.getValue();
    const formatedData = this.templateService.setData(data).dataToJSON();
    return formatedData;
  }

  private getFtlWarpId() {
    let ftlOrder = this.ftlOrder.getValue();
    return ftlOrder?.['shipmentIds']?.[0];
  }

  public submit() {
    const data = this.getFormattedData();
    console.log('submit data', data);
    const uploadData = {
      clientId: this.clientId.getValue(),
      mode: this.mode.getValue(),
      ftlWarpId: this.getFtlWarpId(),
      warehouseId: this.warehouseId.getValue(),
      pickupWarehouseId: this.pickupWarehouseId.getValue(),
      ltlShipments: data
    }

    return uploadData;
  }

  public downloadResults(listOrders) {
    const data = this.editingData.getValue();
    const mode = this.mode.getValue();
    let cloneData: string[][] = [];
    const validation = this.templateService.getValidationsFromTemplate() || [];
    cloneData = this.templateService.addShipmentIdsToFile(data, listOrders, validation, mode);
    
    let headers = validation.map(c => c.label);
    cloneData.unshift(headers);
    var csv = cloneData.map(row => row.join(',')).join('\n');
    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    let url = URL.createObjectURL(blob);
    let fileName = `Bulk-order-${Date.now()}.csv`;
    Utils.downloadFile(url, fileName);
  }

  public getWarehousePickupTime() {
    let data = this.getDataFromExcel();
    let firstRow = data.filter(row => !!row.deliverDate)[0];
    let pickupTime = firstRow?.pickupTime;
    if(pickupTime.length) return pickupTime;
    let deliverDate = firstRow?.deliverDate;
    return this.templateService.getWarehousePickupTime(this.clientId.getValue(), this.warehouseId.getValue(), deliverDate);
  }

  public reset() {
    this.step.next(1);
    this.mode.next('both');
    this.clientId.next(null);
    this.ftlOrder.next(null);
    this.fileData.next(null);
    this.warehouseId.next(null);
    this.pickupWarehouseId.next(null);
    this.errorMessage.next(null);
    this.editingData.next([]);
  }

  public revertFileRawData() {
    const fileData = this.fileData.getValue();
    this.fileData.next({ fileName: fileData.fileName, data: this.originalFileRawData });
  }

  public formatRawData() {
    const fileData = this.fileData.getValue();
    this.originalFileRawData = fileData?.data || [];
    const dataFormatted = this.templateService.formatRawData(this.originalFileRawData);
    this.fileData.next({ fileName: fileData.fileName, data: dataFormatted});
  }
  
}

//1. choose customerId, mode, templateId, file
//2. config header: mapping data
//3. validate data: table-editable
//4. get formatted data
//5. send data to server