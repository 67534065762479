import { BaseComponent } from "@abstract/BaseComponent";
import { Component, forwardRef, Inject, Injector, Input } from "@angular/core";
import { BaseFormItem } from "@app/admin/base/form-item";
import { Const } from "@const/Const";
import { ApiService } from "@services/api.service";
import { Log } from "@services/log";
import { MasterData } from "@services/master.data";
import { AddressUS, Order, Shipment } from "@wearewarp/types/data-model";
import { GoPuffUploadService } from "../../gopuffUpload.service";
import { BehaviorSubject, Subscription } from "rxjs";
import { debounceTime, switchMap } from "rxjs/operators";
import { BaseInputComponent } from "@app/admin/components/forms/base-custom-input";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { WarpId } from "@wearewarp/universal-libs";
import { Observable } from "@ckeditor/ckeditor5-utils";

@Component({
  selector: 'searchFTLInput',
  templateUrl: './index.html',
  styleUrls: ['./index.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchFTLComponent),
      multi: true
    }
  ]
})
export class SearchFTLComponent extends BaseInputComponent {
  loading: boolean = false;
  listOrders: Order[] = null;
  selected: Order = null;
  searchString: BehaviorSubject<string> = new BehaviorSubject<string>('');

  displayInfo: {
    pickup: {
      locationName: string,
      addressText: string,
    },
    dropoff: {
      locationName: string,
      addressText: string,
    }
  }

  @Input() clientId: string;

  constructor(@Inject(Injector) protected injector: Injector, private api: ApiService) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    //search string
    this.searchString.pipe(
      debounceTime(300)
    ).subscribe(search => this.searchFtlOrder(search))
  }

  onSearchChange(search: string) {
    this.searchString.next(search);
  }

  private request: Subscription;
  searchFtlOrder(search) {
    console.log('search', search);
    if (!this.clientId) {
      return;
    }
    if (!search) return

    //unsubscribe previous request
    if (this.request) {
      this.request.unsubscribe();
    }
    this.loading = true;
    this.listOrders = null;
    let condition = {
      clientIds: [this.clientId],
      shipmentType: Const.ShipmentTypes.fullTruckLoad
    }

    let params = { filter: JSON.stringify(condition) };
    params['search'] = search;
    let qs = new URLSearchParams(params).toString();
    let url = Const.APIV2(`orders?skip=0&limit=10&${qs}`);
    this.request = this.api.GET(url).subscribe(
      resp => {
        this.listOrders = resp?.data?.list_data ?? null;
      }, err => {
        Log.e(err);
      }, () => {
        this.loading = false;
      }
    );
  }

  public isSameValue(value1: Order, value2: Order) {
    if (!value1 || !value2) return false;
    return value1?.id == value2?.id;
  }

  set value(value: Order) {
    if (this.isSameValue(this.selected, value)) return;
    super.value = value;
    if (value && (!this.listOrders || this.listOrders.length === 0)) {
      this.listOrders = [value];
    }
    this.buildDisplayInfo();
  }

  buildDisplayInfo() {
    const shipment = this.selected?.metadata?.shipments?.[0];
    const pickup = shipment?.deliveryInfos?.find(info => info?.type === Const.TaskType.PICKUP);
    const dropoff = shipment?.deliveryInfos?.find(info => info?.type === Const.TaskType.DROPOFF);
    this.displayInfo = {
      pickup: {
        locationName: pickup?.locationName,
        addressText: MasterData.getAddressText(pickup?.addr)
      },
      dropoff: {
        locationName: dropoff?.locationName,
        addressText: MasterData.getAddressText(dropoff?.addr)
      }
    }
  }

  public showOrderCode(order) {
    return WarpId.showOrderCode(order)
  }

}