<div nz-row class="selected-template" [nzGutter]="16">
  <div nz-col class="gutter-row" [nzSpan]="24">
    <div class="form-label-v2">FTL Warp ID<span class="label-mark-required"></span></div>
    <nz-form-item style="margin-bottom: 12px;">
      <nz-form-control>
        <nz-select nzBackdrop="true" nzPlaceHolder="Search by WARP ID, Reference Number or Tracking Number" nzAllowClear
          [(ngModel)]="value" nzShowSearch [nzServerSearch]="true"
          [nzNotFoundContent]="tplNotfound"
          [nzLoading]="loading"
          (nzOnSearch)="onSearchChange($event)" nzOptionHeightPx="30">
          <ng-container *ngFor="let order of listOrders">
            <nz-option nzCustomContent [nzLabel]="showOrderCode(order)" [nzValue]="order">
              <div>Order: {{showOrderCode(order)}}</div>
            </nz-option>
          </ng-container>
        </nz-select>
      </nz-form-control>
    </nz-form-item>

  </div>
  <div style="width: 100%; margin-left: 8px;">
    <div nz-row [nzGutter]="16">
      <div *ngIf="displayInfo?.pickup?.locationName" nz-col class="gutter-row" [nzSpan]="12">
        <div>Origin Pickup Cross Dock location</div>
        <div class="location-info">{{displayInfo?.pickup?.locationName}}</div>
        <div class="location-info">{{displayInfo?.pickup?.addressText}}</div>
      </div>
      <div *ngIf="displayInfo?.dropoff?.locationName" nz-col class="gutter-row" [nzSpan]="12">
        <div>Middle Cross Dock location</div>
        <div class="location-info">{{displayInfo?.dropoff?.locationName}}</div>
        <div class="location-info">{{displayInfo?.dropoff?.addressText}}</div>
      </div>
    </div>
  </div>
</div>
<ng-template #tplNotfound>
  <div *ngIf="!clientId" class="no-data"><i nz-icon nzType="search" nzTheme="outline"></i> Please select the customer first!</div>
  <div *ngIf="clientId && loading" class="no-data"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
  <div *ngIf="clientId && !loading" class="no-data"><i nz-icon nzType="search" nzTheme="outline"></i> No data</div>
</ng-template>