<div class="flex box-container">
  <div class="row" *ngFor="let field of value">
    <div class="field">
      <div class="label">{{field.label}} <span *ngIf="field.required" class="label-mark-required"></span></div>
      <div class="description" *ngIf="field.description">{{field.description}}</div>
    </div>
    <div class="icon"><span nz-icon nzType="arrow-right" nzTheme="outline"></span></div>
    <div class="select-form">
      <nz-select nzBackdrop="true" [ngModel]="field.headers" (ngModelChange)="onSelect(field, $event)"
        [nzMode]="field.multiple ? 'multiple': 'default'" nzAllowClear>
        <nz-option *ngFor="let header of headers" [nzValue]="header" [nzLabel]="header"></nz-option>
      </nz-select>
    </div>
  </div>
</div>