<div class="table-editable">
  <nz-table nzBordered [nzData]="selected" nzSize="small" [nzScroll]="{x: 1600}" [nzShowPagination]="false">
    <thead>
      <tr>
        <th *ngFor="let column of columns" [nzWidth]="column.width">{{column.label}}</th>
        <th nzWidth="100px">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of dataLength; index as i;trackBy:trackByIndex" class="editable-row">
        <td *ngFor="let column of columns;">
            <editable-cell [columnKey]="column.key" [rowIndex]="i"></editable-cell>
        </td>
        <td>
          <a nz-popconfirm nzPopconfirmTitle="Sure to delete?"
            (nzOnConfirm)="onDelete(i)">Delete</a>
        </td>
      </tr>
    </tbody>
  </nz-table>
</div>