import { BaseComponent } from "@abstract/BaseComponent";
import { Component } from "@angular/core";
import { DrawerService } from "@app/drawers/drawer.service";
import { GoPuffUploadService } from "../../gopuffUpload.service";
import { Const } from "@const/Const";

@Component({
  selector: 'gopuff-upload-general-info',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class GopuffGeneralInfoComponent extends BaseComponent {
  constructor(private gopuffUploadService: GoPuffUploadService) {
    super();
  }
  //crossdockOnly, both, lastmileOnly, linehaulOnly
  public mode = [
    {
      value: 'both',
      label: 'Lastmile for linehaul',
      forms: {
        ftlOrder: true,
        warehouseId: false,
        pickupWarehouseId: false
      }
    },
    {
      value: 'lastmileOnly',
      label: 'Only lastmile (No Linehaul)',
      forms: {
        ftlOrder: false,
        warehouseId: true,
        pickupWarehouseId: false
      }
    },
    {
      value: 'linehaulOnly',
      label: 'Only linehaul (No Last Mile)',
      forms: {
        ftlOrder: true,
        warehouseId: false,
        pickupWarehouseId: false
      }
    },
    {
      value: 'crossdockOnly',
      label: 'Only crossdock',
      forms: {
        ftlOrder: false,
        warehouseId: true,
        pickupWarehouseId: true
      }
    }
  ]

  public templateIds = [
    {
      label: 'One order per row',
      value: 'GoPuffByOrder',
    },
    {
      label: 'One pallet per row',
      value: 'GoPuffByPallet',
    }
  ]

  public data = {
    mode: 'both',
    clientId: null,
    ftlOrder: null,
    warehouseId: null,
    pickupWarehouseId: null,
    fileData: null,
    templateId: 'GoPuffByPallet'
  }

  ngOnInit(): void {
    super.ngOnInit();

    for (let key in this.data) {
      this.subscription.add(this.gopuffUploadService[key].subscribe(value => this.data[key] = value));
    }
  }

  onChange(variable, value) {
    console.log('variable', variable, value);
    this.gopuffUploadService[variable].next(value);
    if(variable == 'ftlOrder') this.setValueWarehouseId();
  }

  getModeSetting() {
    return this.mode.find(m => m.value === this.data.mode);
  }

  canNext() {
    let mode = this.getModeSetting();
    for(let key in this.data) {
      if((mode.forms[key] == true || mode.forms[key] != false) && !this.data[key]) {
        return false;
      }
    }
    return true;
  }

  setValueWarehouseId() {
    let ftlOrder = this.data['ftlOrder'];
    if(!ftlOrder) return;
    const shipment = ftlOrder.metadata?.shipments?.[0];
    const pickup = shipment?.deliveryInfos?.find(info => info?.type === Const.TaskType.PICKUP);
    const dropoff = shipment?.deliveryInfos?.find(info => info?.type === Const.TaskType.DROPOFF);
    this.gopuffUploadService['warehouseId'].next(dropoff?.warehouseId);
    this.gopuffUploadService['pickupWarehouseId'].next(pickup?.warehouseId);
  }

  next() {
   this.gopuffUploadService.formatRawData();
   this.gopuffUploadService.step.next(2);
  }
}