import { Injectable } from "@angular/core";
import { Log } from "@services/log";
import { HeaderConfig, iTemplate } from "./interface";
import { Templates } from "./config";

export class TemplateService {
  private templateId: string;
  private clientId: string;
  private headerConfig: HeaderConfig[];
  private data: any[];

  public setTemplateId(templateId) {
    this.templateId = templateId;
    return this
  }
  
  public setClientId(clientId) {
    this.clientId = clientId;
    return this
  }

  public setData(data: any[]) {
    this.data = data;
    return this
  }

  public getHeaderConfigFromTemplate() {
    const templateConfig: iTemplate = this.getTemplateConfig();
    return templateConfig.importHeaders
  }

  public getValidationsFromTemplate() {
    const templateConfig: iTemplate = this.getTemplateConfig();
    return templateConfig.validations
  }

  public formatRawData(rawData: any[]) {
    const templateConfig: iTemplate = this.getTemplateConfig();
    return templateConfig.formatRawData(rawData);
  }

  public dataToJSON() {
    const templateConfig: iTemplate = this.getTemplateConfig();
    return templateConfig.toJSON(this.data);
  }

  public getTemplateConfig() {
    if (!this.templateId) return;
    const templateConfig = Templates[this.templateId];
    if (!templateConfig) {
      throw new Error(`Template [${this.templateId}] not found!`);
    }
    return templateConfig
  }

  public addShipmentIdsToFile(data, listOrders, validations, mode) {
    const templateConfig: iTemplate = this.getTemplateConfig();
    return templateConfig.addShipmentIdsToFile(data, listOrders, validations, mode);
  }

  public getWarehousePickupTime(clientId, warehouseId, deliverDate) {
    const templateConfig: iTemplate = this.getTemplateConfig();
    return templateConfig.getWarehousePickupTime(clientId, warehouseId, deliverDate);
  }

}