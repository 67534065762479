import { Injectable } from "@angular/core";
import { Log } from "@services/log";

export interface HeaderConfig {
  label: string; //label hiển thị
  description?: string; //description hiển thị dưới label
  key: string; //key của json output
  required?: boolean;
  headers?: string | string[], //output sau khi chọn
  suggestions?: string[] //danh sách các gợi ý có thể chonj
}

@Injectable()
export class XlsxUploadService {
  public toJSON({ headerConfig, data, startRow }: { headerConfig: HeaderConfig[], data: any[], startRow?: number }) {
    const headerIndex = this.getHeaderIndex(data);
    try {
      let result = [];
      for (let rowIndex = startRow ?? 0; rowIndex < data.length; rowIndex++) {
        let item = {};
        for (let header of headerConfig) {
          const headerItem = header.headers || [];
          if(Array.isArray(headerItem)) {
            item[header.key] = headerItem.map(columnName => {
              const columnIndex = headerIndex[columnName];
              return data[rowIndex][columnIndex];
            })
          }
          else {
            const columnIndex = headerIndex[<string>header.headers];
            item[header.key] = data[rowIndex][columnIndex];
          }
        }
        result.push(item)
      }

      return result;
    }
    catch (e) {
      Log.e(`Error when convert data from Excel. ${e.message}. header: ${JSON.stringify(headerConfig)}. data: ${JSON.stringify(data)}`)
    }
  }

  getHeaderIndex(data) {
    const headerRow = data[0];
    let headerIndex = {};
    for (let i = 0; i < headerRow.length; i++) {
      headerIndex[headerRow[i]] = i;
    }
    return headerIndex;
  }
}