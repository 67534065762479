import { Component, ElementRef, EventEmitter, forwardRef, Inject, Injector, Input, Output } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { BaseInputComponent } from "@app/admin/components/forms/base-custom-input";
import { InputModule } from "../../../inputs/input.module";
import { ReplaySubject } from "rxjs";
import { DOCUMENT } from "@angular/common";

const IGNORECLASSES = ['cdk-overlay-pane', 'cdk-overlay-backdrop'];
@Component({
  selector: 'dynamic-input',
  templateUrl: './index.html',
  styleUrls: ['./index.scss'],
  host: {
    '(document:click)': 'onBlur($event)',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DyanmicInputComponent),
      multi: true
    }
  ]
})
export class DyanmicInputComponent extends BaseInputComponent {
  @Input() editInput: any;
  @Output() onFinish = new EventEmitter();

  inputInjector: Injector;
  dynamicModule: any = InputModule;
  data: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    @Inject(Injector) protected injector: Injector,
    private _eref: ElementRef,
    @Inject(DOCUMENT) private _document: HTMLDocument
  ) {
    super(injector);
    this.createInjector();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.data.subscribe((value) => {
      this.value = value;
    })
  }

  getIgnores() {
    let elements = [];
    for (let className of IGNORECLASSES) {
      elements.push(this._document.querySelectorAll(`.${className}`));
    }
    return elements;
  }

  inIgnoreElements(target) {
    let elements = this.getIgnores();
    for (let element of elements) {
      for (let el of element) {
        if (el.contains(target)) return true;
      }
    }
    return
  }

  onBlur(event) {
    const parentNode = this._eref.nativeElement?.parentNode?.parentNode;
    if (!parentNode.contains(event.target) && !this.inIgnoreElements(event.target))
      this.onFinish.emit();
  }

  createInjector() {
    if (this.inputInjector) return this.inputInjector;
    this.inputInjector = Injector.create({
      providers: [
        { provide: 'data', useValue: this.data },
      ],
      parent: this.injector,
    });

    return this.inputInjector;
  }

  writeValue(value: any): void {
    super.writeValue(value);
    this.data.next(this.selected);
  }

}