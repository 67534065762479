import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { SharedModule } from "@app/admin/shared/shared.module";
import { SelectBySearchingModule } from "@libs/select-client/module";
import { NzPaginationModule } from "ng-zorro-antd/pagination";
import { XLSXUpload } from ".";
import { XlsxUploadService } from "./xlsxUpload.service";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzPaginationModule,
    ScrollingModule,
    SelectBySearchingModule,
    SharedModule,
  ],
  declarations: [
    XLSXUpload
  ],
  exports: [
    XLSXUpload,
  ],
  providers: [
    XlsxUploadService
  ]
})
export class XlsxUploadModule {
}
