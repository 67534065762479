<div class="box-container">
  <div class="box-content">
    <div style="font-size: 24px; font-weight: 500; line-height: 31.2px; padding-top: 20px;">
      Map fields
    </div>
    <div class="top20 bottom20 text-content-bold">Select the relevant field in your CSV file to match with our WARP field.</div>
    <div class="row title" style="font-weight: 500;">
      <div class="field">WARP field</div>
      <div class="select-form">CSV field</div>
    </div>
  </div>
</div>
<mapping-data [headers]="getHeader()" (ngModelChange)="onChange($event)" [(ngModel)]="config"></mapping-data>
<div class="flex top20 box-button">
  <button nz-button nzType="default" (click)="back()">
    Back
  </button>
  <button nz-button nzType="primary" (click)="next()" [disabled]="!canNext()">
    Save & Continue
  </button>
</div>