import { BehaviorSubject } from "rxjs";

export class APIConcurrent {
    private pendingData: any[] = [];
    private handleFn: Function
    private response: BehaviorSubject<any[]> = new BehaviorSubject([]);
    private interval: any;


    constructor(private options: {
        intervalTime: number
    }) {}

    registryHandler(handleFn: Function){
        this.handleFn = handleFn;
        this.start();
        return this;
    }

    add(data: any){
        this.pendingData.push(data);
        return this.response
    }

    private async execute(){
        if(!this.pendingData.length) return;
        const data = this.pendingData.splice(0, this.pendingData.length)
        const response = await this.handleFn(data);
        this.response.next(response);
    }

    private start(){
        clearInterval(this.interval);
        this.interval = setInterval(this.execute.bind(this), this.options.intervalTime);
    }

}