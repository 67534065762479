import { Component, Inject } from "@angular/core";
import { ReplaySubject } from "rxjs";
@Component({
  selector: 'base-component',
  template: '<div></div>'
})
export class BaseComponent {

  protected _value: any;
  constructor(
    @Inject('data') public data: ReplaySubject<any>,
  ) { }

  ngOnInit() {
    this.data.subscribe((value) => {
      this._value = value;
    })
  }

  get value() {
    return this._value;
  }
  set value(value) {
    this._value = value;
    this.data.next(value);
  }
}